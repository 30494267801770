import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Adsense } from '@components/Adsense/Adsense'
import { Box, Label, Input, Textarea, Button, Message, Spinner, Text} from 'theme-ui'
import { Link } from 'gatsby';


const PageCreditoGobiernoCo = props => {
  

  return (
    <Layout {...props}>
      <Seo title='Prestamos-Nacion' />
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2292291997787849"
     crossOrigin="anonymous"></script>
      <Divider />
      <Adsense />

      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Descubre el Subsidio de $672.000 y el Revolucionario Programa Creo del Gobierno: Acceso a Créditos de hasta $4 Millones💸'
          subheader='Empoderando Finanzas Familiares: Descubre el Subsidio de $672.000 y el Revolucionario Programa Creo del Gobierno Colombiano'
        />
      </Stack>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Link to="/prestamos-personales-co">
          <Button style={{margin: '5px', width: '350px'}} variant="white">💰 Solicita tu préstamo aqui 💰 </Button>
        </Link>
      </div>
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
          
Descubre el Subsidio de $672.000 y el Revolucionario Programa Creo del Gobierno: Acceso a Créditos de hasta $4 Millones

En el vibrante panorama de beneficios ofrecidos por las cajas de compensación en Colombia, destaca el Subsidio Familiar de $672.000. Esta invaluable prestación, respaldada por el Ministerio de Trabajo, tiene como misión aliviar las cargas familiares de los trabajadores más vulnerables, ofreciendo montos mensuales que varían entre $56.000 y $128.800.
          '
        />
      </Stack>
      <Adsense />

      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
          <b>¿Quiénes pueden acceder al Subsidio de $672.000?</b>
          <br>
El acceso a este subsidio está disponible para diversas categorías de beneficiarios, como hijos, hijastros, hermanos huérfanos y padres mayores de 60 años sin pensión, retiro o salario.
<br>
<b>Requisitos para Obtener el Subsidio Familiar:</b>
<br>
Para ser elegible, es necesario ser afiliado a Colsubsidio, registrar a tus dependientes, no superar un salario mensual de cuatro salarios mínimos y haber trabajado al menos 96 horas al mes.
<br>
<b>Pasos Sencillos para Solicitar el Subsidio Familiar de $672.000:</b>
<br>
- Accede a la página web de Colsubsidio e ingresa con tu cédula de identidad y contraseña.<br>
- Dirígete a la sección "Mi Grupo Familiar".<br>
- Activa el botón "Solicitar Subsidio Familiar".<br>
- Sube a la plataforma los documentos requeridos.<br>
- Confirma la radicación de los documentos y el estado de la solicitud a través del correo electrónico.<br>
          '
        />
      </Stack>
      <Adsense />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Link to="/prestamos-personales-co">
          <Button style={{margin: '5px', width: '350px'}} variant="white">💰 Solicita tu préstamo aqui 💰 </Button>
        </Link>
      </div>
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
          
          <b>¡Novedad Financiera en Colombia! Descubre el Programa Creo del Gobierno: Créditos de hasta $4 Millones</b>
<br>
En un esfuerzo por contrarrestar las peligrosas prácticas de préstamos informales, el Gobierno colombiano ha lanzado el programa Creo. Este innovador proyecto, aprobado el 11 de diciembre, tiene como objetivo fomentar la economía popular mediante una línea de préstamos blandos, accesibles para los ciudadanos colombianos, de hasta $4 millones.
<br>
<b>Objetivo del Crédito del Gobierno Creo:</b>
<br>
Creo se centra en promover el acceso a financiamiento formal para la economía popular, abarcando oficios y ocupaciones mercantiles y no mercantiles desarrolladas por negocios personales, familiares o microempresas. Este programa está especialmente diseñado para aquellos que necesitan préstamos, pero carecen de fiadores o garantías exigidas por el sistema bancario, brindándoles facilidades de pago de hasta 24 meses.
<br>
<b>Cierre de la Brecha de Desigualdad Económica:</b>
<br>
El programa Creo aspira a cerrar la brecha de desigualdad económica en distintos sectores sociales del país, proporcionando acceso a crédito flexible para impulsar el bienestar económico y financiero de estos sectores.
<br>
<b>Apoyo Institucional al Proyecto Creo:</b>
<br>
El respaldo integral del proyecto Creo proviene de los ministerios de Hacienda y Crédito Público, Industria y Turismo, Comercio, Agricultura y Desarrollo Rural. Además, instituciones financieras como el Banco Agrario de Colombia, Banco de Comercio Exterior de Colombia (Bancóldex), Fondo Nacional de Garantías (FNG) y el Fondo para el Financiamiento del Sector Agropecuario (Finagro) se suman a esta iniciativa, facilitando los recursos necesarios para materializar el cambio financiero esperado. ¡Aprovecha esta oportunidad para fortalecer tu posición económica y la de tu comunidad!
          '
        />
      </Stack>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Link to="/prestamos-personales-co">
          <Button style={{margin: '5px', width: '350px'}} variant="white">💰 Solicitá tu préstamo acá 💰 </Button>
        </Link>
      </div>
      <Adsense />

    </Layout>
  )
}

export default PageCreditoGobiernoCo
